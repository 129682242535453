.creditRequestContainer {
  width: 845px;
  height: -webkit-fill-available;
  max-height: 984px;
  top: 96px;
  right: 0px;
  position: fixed;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.creditRequestWaiting {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
  flex-direction: column;
}

.detailPageContainer {
  background-color: #ffffff;
  width: calc(100% - 211px);
  height: 100%;
  max-height: 984px;
  left: 366px;
  top: 96px;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.orderSummaryWaiting {
  display: flex;
  width: 660px;
  height: 100%;
  left: 0px;
  top: 0px;
  align-items: center;
  justify-content: center;
}

.underlay {
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  visibility: visible;
  background-color: #00000080;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
}
