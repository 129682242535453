.creditRequestFooter {
  width: 100%;
  border-bottom-color: #f1f2f2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  height: 80px;
  box-shadow: 0px -2px 4px 0px #00000040;
  .submitButton {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
}
